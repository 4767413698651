import React from 'react'
import { Container} from "react-bootstrap";
import { TextBlock } from "../Core";
import { renderSingleImage } from '../../utils/images';

function OneTwoThree(props) {

    return (
        <Container style={{marginTop: 80}}>
  
        <div>
            <div id='floatedRight' data-aos='fade-left'>
                {renderSingleImage(props.localImages, props.content.image1, 'defaultImage', props.content.image1)}
            </div>

            <TextBlock>
            <h2>{(props.leadCopyContent && props.leadCopyContent.title1) || props.content.title1}</h2>
              <div dangerouslySetInnerHTML={{ __html: (props.leadCopyContent && props.leadCopyContent.text1) || props.content.text1}} />
            </TextBlock>
        </div>

          
        <div>
            <div id='floatedLeft' data-aos='fade-right'>
            {renderSingleImage(props.localImages, props.content.image2, 'defaultImage', props.content.image2)}
            </div>

            <TextBlock>
            <h2>{(props.leadCopyContent && props.leadCopyContent.title2) || props.content.title2}</h2>
              <div dangerouslySetInnerHTML={{ __html: (props.leadCopyContent && props.leadCopyContent.text2) || props.content.text2}} />
            </TextBlock>
        </div>

         <div>
            <div id='floatedRight' data-aos='fade-left'>
            {renderSingleImage(props.localImages, props.content.image2, 'defaultImage', props.content.image2)}
            </div>

            <TextBlock>
            <h2>{(props.leadCopyContent && props.leadCopyContent.title3) || props.content.title3}</h2>
              <div dangerouslySetInnerHTML={{ __html: (props.leadCopyContent && props.leadCopyContent.text2) || props.content.text3}} />
            </TextBlock>
        </div>

        </Container>
    )
}

export default OneTwoThree