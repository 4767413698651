import React from 'react'
import { graphql } from "gatsby"
import Page from '../components/Pages/Page';
import Lead from '../components/Pages/Lead';


function LeadTemplate(props) {
    
    return (
        <Page {...props} PageComponent={ Lead } />
    )

}

export const queryLead = graphql`
fragment leadFields on NewSites {
    ...globalFields
    pages {
        type
        id
        aUri
        content {
            h1
            subTitle
            text
            image
            images
            seoTitle
            seoDescription
            oneTwoThree{
                active
                image1
                image2
                image3
                text1
                text2
                text3
                title1
                title2
                title3
            }
            oneTwoThreeB{
                active
                step1
                step2
                step3
            }
      
            stars{
                text1
                text2
                text3
            }

            salesPitch{
                list
                quote
                quoteImage
                benefit1
                benefit2
                benefit3
                benefit4
                benefit5
                benefit6
                benefit7
            }
            
            formLead{
                field1
                field2
                field3
                field4
                field5
                field6
                field7
                field8
                field9
                field10
                field11
                field12
                button
                source
                to
            }
         
        }
    }
}
query leadQuery($subDomain: String) {
    newSites(subDomain: {eq: $subDomain}) {
        ...leadFields
    }
}`

export default LeadTemplate