import React from 'react'
import { renderSingleImage } from '../../utils/images'

function SalesPitch(props) {
    const imageId = 'salesPitchImage'
    const alt = props.content.quote


    return (
        <>
        <div>
        <div id='quotePic'>
            <div id='floatedLeftQuote'>
                    {renderSingleImage(props.localImages, props.content.quoteImage, imageId, alt)}
            </div>
        </div>
        <h3 id='leadh3' style={{fontSize: 35, fontWeight: 700, marginBottom :30}}>{props.content.quote}</h3>
        </div>
        {props.content.list !== "" ? 
        <>
            <div dangerouslySetInnerHTML={{ __html: props.content.list}} />
            </>
        : 
            <>
      
                <ul id='leadul'>
                    <li>{props.content.benefit1}</li>
                    <li>{props.content.benefit2}</li>
                    <li>{props.content.benefit3}</li>
                    <li>{props.content.benefit4}</li>
                    <li>{props.content.benefit5}</li>
                    <li>{props.content.benefit6}</li>
                    <li>{props.content.benefit7}</li>
                </ul>
            </>
        }
        
     
        </>
    )
}

export default SalesPitch