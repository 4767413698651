import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import ReactStars from "react-rating-stars-component";

function TestimonialBlock(props) {


  const firstExample = {
    size: 18,
    value: 5,
    color: "rgb(251, 160, 58)",
    activeColor: "#ffc107",
    edit: false,
  };

  
  return (
    <Container fluid style={{background: 'rgb(247, 247, 251)'}}>
      <Container style={{maxWidth: 1240, marginTop: 30, marginBottom: 10}}>
          <Row style={{maxWidth: 1280, margin: 'auto', marginTop: -35, paddingBottom: 70, paddingTop: 70}} data-aos="fade-up">
              <Col sm={4}>
                  <div style={{maxWidth: 100, margin: 'auto'}}> <ReactStars {...firstExample} /></div>
                  <div style={{textAlign: 'center', fontStyle: "italic", color: 'black', fontSize: 19, fontWeight: 300, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}}>"{props.text1}"</div>
                  </Col>
              <Col sm={4} id="mobileStars">
              <div style={{maxWidth: 100, margin: 'auto'}}> <ReactStars {...firstExample} /></div>
                  <div style={{textAlign: 'center', fontStyle: "italic", color: 'black', fontSize: 19, fontWeight: 300, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}}>"{props.text2}"</div>
              </Col>
              <Col sm={4} id="mobileStars">
              <div style={{maxWidth: 100, margin: 'auto'}}> <ReactStars {...firstExample} /></div>
                  <div style={{textAlign: 'center', fontStyle: "italic", color: 'black', fontSize: 19, fontWeight: 300, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}}>"{props.text3}"</div>
              </Col>
          </Row>
      </Container>

      </Container>
  )
}

export default TestimonialBlock