import React from 'react';
import { renderSingleImage } from '../../utils/images';
import { Text } from "../Core";
import { Col, Button} from "react-bootstrap";
import { Link} from "gatsby"

const Cards = ( { pages, localImages, clickable } ) => {

    const description = (page) => {
        if(page.content.text.includes("</h2>")){
            return (
                <Text>
                    <span style={{fontSize: 21}} dangerouslySetInnerHTML={{ __html: page.content.text.split("</h2>")[1].replace(/(<([^>]+)>)/gi, "").trim().substring(0,100) + '...' }} ></span> 
                </Text>
            )
        }
        else if(!page.content.text.includes("</h2>") && page.content.text.includes("</p>")){
            return (
                <Text>
                    <span style={{fontSize: 21}} dangerouslySetInnerHTML={{ __html: page.content.text.split("</p>")[0].replace(/(<([^>]+)>)/gi, "").trim().substring(0,100) + '...' }} ></span> 
                </Text>
            )
        }
        else return (
            <div>
                {page.content.text.substring(0,100)}
            </div>
        )
    }


    return (
        <>
        {pages.map( (page,index) => 
            <Col key={index} sm={4}>
            {clickable === false ?
            <div id='textOnHover'>
                <div id='cardOnHover' data-aos="fade-up">
                    {page.content.images ? renderSingleImage(localImages, page.content.images[0]) : null}
                    <div style={{padding: 15, marginTop: 5}}>
                        <h3 style={{fontSize: 24, fontWeight: 700}}>{page.content.h1}</h3>
                        <div>{description(page)}</div>

                    </div>
                </div>
            </div>
            :
            <Link to={`/${page.aUri}/`} id='textOnHover'>
                <div id='cardOnHover' data-aos="fade-up">
                    {page.content.images ? renderSingleImage(localImages, page.content.images[0]) : null}
                    <div style={{padding: 15, marginTop: 5}}>
                        <h3 style={{fontSize: 24, fontWeight: 700}}>{page.content.h1}</h3>
                        <div>{description(page)}</div>
                        <Button style={{marginTop: 10}}>Ver Màs</Button>
                    </div>
                </div>
            </Link>
            }
            </Col>
        )}
        </>
        
    ) 
}

export default Cards;