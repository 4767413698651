import React from 'react'
import { Container, Col, Row} from "react-bootstrap";
import { renderSingleImage } from '../../utils/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'

const OneTwoThreeB = (props) => {

  return (
      <>
    <Container className='text-center' style={{marginTop: 80}}>
        <h2 style={{color: '#212529', fontSize: 60, fontWeight: 700, letterSpacing: '-2.5px', marginBottom : 0}}>Choisir SAS Maufay en 3 étapes</h2>
        <h3 style={{letterSpacing: '-1px', fontWeight: 300, marginTop: -20}}>Vous ne le regretterez pas !</h3>
    </Container>
    <Container>
        <Row id="rowOneTwoThreeBa" style={{maxWidth: 900, margin: 'auto', marginTop: 120}}>
            <Col sm={6} data-aos="fade-right" data-aos-duration="750">

            <h2 class='123B' style={{fontSize: 40, fontWeight: 700, color: '#212529', letterSpacing: '-2.5px', display: 'flex', marginTop: -20}}>
                <div style={{background: props.bg, borderRadius: '50%', width: 50, height: 50}}>
                    <span style={{color: 'white', padding: 15, marginRight: 10}}>1</span>
                </div>
                <div>
                <span style={{marginLeft: 10}}>Remplissez <span style={{textDecoration: 'underline'}}>le formulaire</span>.</span>
                </div>
         
                </h2>
            <p style={{fontSize: 22}}>Un formulaire simple qui vous permettra d'indiquer vos besoins :</p>
            <ul style={{listStyle: 'square'}}>
                <li style={{marginBottom: 0, fontSize: 21,
                fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'}}>Services souhaités</li>
                <li style={{marginBottom: 0, fontSize: 21,
                                fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'
                }}>Vos contraintes</li>
                <li style={{marginBottom: 0, fontSize: 21,
                                fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'
                }}>Dates et niveau d'urgence</li>
            </ul>
            </Col>
            <Col className='text-center' sm={6}>
            <a href="#">
                <div >
                {renderSingleImage(props.localImages, 'global%2Fbackground-grey', 'defaultImage', props.subDomain)}
                </div>
                <div data-aos="fade-left" data-aos-duration="750" id='picOneTwoThreeB'>
                {renderSingleImage(props.localImages, 'global%2Fform', 'defaultImage', props.subDomain)}
                </div>
            </a>
            </Col>
        </Row>
        <Container>
            <Row id="rowOneTwoThreeB" style={{maxWidth: 900, margin: 'auto', marginTop: 160}} className="flex-column-reverse flex-sm-row">
                <Col className='text-center' sm={6}  >
                <a href="#">
                    <div >
                    {renderSingleImage(props.localImages, 'global%2Fbackground-grey', 'defaultImage', props.subDomain)}
                    </div>
                    <div data-aos="fade-right" data-aos-duration="750" id='picOneTwoThreeB'>
                    {renderSingleImage(props.localImages, 'global%2Ftime', 'defaultImage', props.subDomain)}
                    </div>
                </a>
                    </Col>
                <Col sm={6} data-aos="fade-left" data-aos-duration="750">
             
                <h2 style={{fontSize: 40, fontWeight: 700, color: '#212529', letterSpacing: '-2.5px', display: 'flex', marginTop: -20}}>
                    <div style={{background: props.bg, borderRadius: '50%', width: 50, height: 50}}>
                    <span style={{color: 'white', padding: 15, marginRight: 10}}>2</span>
                    </div>
                    <span style={{marginLeft: 10}}>Contact sous <span style={{textDecoration: 'underline'}}>24 heures</span>.</span></h2>
                    <p style={{fontSize: 22}}>Toutes nos demandes sont traitées sous 24 heures ouvrables.</p>
                    <p style={{marginBottom : 30}}><a href="#">Contactez-moi <FontAwesomeIcon icon={faArrowRight} /></a></p>
                </Col>  
            </Row>
        </Container>
        <Row id="rowOneTwoThree" style={{maxWidth: 900, margin: 'auto', marginTop: 160}}>
            <Col sm={6} data-aos="fade-right" data-aos-duration="750">
            <h2 style={{fontSize: 40, fontWeight: 700, color: '#212529', letterSpacing: '-2.5px', display: 'flex', marginTop: -20}}>
                <div style={{background: props.bg, borderRadius: '50%', width: 50, height: 50}}>
                <span style={{color: 'white', padding: 15, marginRight: 10}}>3</span>
                </div>
                <span style={{marginLeft: 10}}>On s’occupe de <span style={{textDecoration: 'underline'}}>tout </span>!</span></h2>
                <p style={{fontSize: 22}}>Une fois le devis accepté, notre équipe se lance dans la réalisation de vos travaux.</p>
                <p><a href="#">Demande de devis <FontAwesomeIcon icon={faArrowRight} /></a></p>
            </Col>
            <Col className='text-center' sm={6}>
            <a href="#">
                <div >
                {renderSingleImage(props.localImages, 'global%2Fbackground-grey', 'defaultImage', props.subDomain)}
                </div>
                <div data-aos="fade-left" data-aos-duration="750" id='picOneTwoThreeB'>
                {renderSingleImage(props.localImages, 'global%2Fgoal', 'defaultImage', props.subDomain)}
                </div>
            </a>
           
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default OneTwoThreeB