import React from 'react'
import { Container, Row, Col} from "react-bootstrap";
import SalesPitch from "../Blocks/SalesPitch"
import FormLead from "../Blocks/FormLead"
import OneTwoThree from "../Blocks/OneTwoThree"
import OneTwoThreeB from "../Blocks/OneTwoThreeB"
import Stars from '../Blocks/Stars'
import Seo from '../seo'
import Cards from '../Blocks/Cards';
import Breadcrumbs from "../Blocks/Breadcrumbs"

function Lead(props) {

  const services = props.pages.filter(page => page.type === 'service')
  const portfolio = props.pages.filter(page => page.type === 'port')
  const index = props.pages.filter(page => page.type === 'index')
  const leadContent = props.page.type === 'leadCopy' ? props.pages.filter(page => page.type === 'lead')[0].content : null
  const global = index[0].content
  const servicesWord = props.pages.filter(page => page.type === 'services')[0].content.h1

    return (
      <>
      

      {leadContent ? 
   
          <>
             
            <Seo title={props.content.seoTitle || props.content.h1}
          description={props.content.seoDescription}
          metas={props.global.defaults.metas}
      />
  <Breadcrumbs home={props.global.defaults.lang.home} contentH1={props.content.h1}/>
        <Container fluid style={{background: '#f6f6f6bf', marginTop: -16}}>
          <Container>
          <div id='h1p'>
              <h1 id='leadh1' style={{textAlign: 'center'}}>{props.content.h1}</h1>
              <p id='leadsubtitle' style={{textAlign: 'center'}}>{props.content.subTitle || ''}</p>
          </div>
          <Row style={{paddingBottom : 100}}>
          <Col sm={6} id='salesPitchOnDesktop'>
          <SalesPitch content={leadContent.salesPitch} localImages={props.localImages}/> 
            </Col>
            <Col sm={6}>
            <FormLead content={leadContent.formLead} defaults={props.global.defaults}/>
            </Col>
            <Col sm={6} id='salesPitchOnMobile'>
          <SalesPitch content={leadContent.salesPitch} localImages={props.localImages}/> 
            </Col>
          </Row>
          </Container>
          </Container>
          <hr style={{border: '3px solid #d1d4d7', width: 1000, marginTop: -10}}></hr>
          
          <Stars
            text1={global.stars.text1}
            text2={global.stars.text2}
            text3={global.stars.text3}/>
          <OneTwoThree content={leadContent.oneTwoThree} leadCopyContent={props.content.oneTwoThree} localImages={props.localImages}/>
          <OneTwoThreeB content={leadContent.oneTwoThreeB} leadCopyContent={props.content.oneTwoThreeB} localImages={props.localImages}/>
          <Container>
              <div style={{marginTop: 180, marginBottom: 50}}>
                <h2 style={{textAlign: 'center'}}>Servicios</h2>
                <p style={{textAlign: 'center'}}>{''}</p>
              </div>
              <Row>
                <Cards pages={services} localImages={props.localImages} />
              </Row>
          </Container>
          </>
          

    
     
       :
      <>
      <Seo
          title={props.content.seoTitle || props.content.h1}
          description={props.content.seoDescription}
      />
   <Breadcrumbs home={props.global.defaults.lang.home} contentH1={props.content.h1}/>
        <Container fluid style={{background: 'rgb(247 247 251)', marginTop: -16}}>
       
          <Container>
          <div id='h1p'>
              <h1 id='leadh1' style={{textAlign: 'center'}}>{props.content.h1}</h1>
              <p id='leadsubtitle' style={{textAlign: 'center'}}>{props.content.subTitle || ''}</p>
          </div>
          <Row style={{paddingBottom : 100}}>
          <Col sm={6} id='salesPitchOnDesktop' data-aos="fade-right">
          <SalesPitch content={props.content.salesPitch} localImages={props.localImages}/> 
            </Col>
            <Col sm={6}>
              <FormLead content={props.content.formLead}  defaults={props.global.defaults}/>
            </Col>
            <Col sm={6} id='salesPitchOnMobile'>
          <SalesPitch content={props.content.salesPitch} localImages={props.localImages}/> 
            </Col>
          </Row>
          </Container>
          </Container>
          {/* <Container fluid id='leadStars' style={{background: 'linear-gradient(to right, rgb(31, 33, 43), rgb(0, 35, 255))', paddingTop: 70}}> */}
          <hr style={{border: '3px solid #d1d4d7', width: 1000, marginTop: -10}}></hr>
          <Stars
            text1={global.stars.text1}
            text2={global.stars.text2}
            text3={global.stars.text3}/>
          {/* </Container> */}
          {props.content.oneTwoThree.active === true ? <OneTwoThree content={props.content.oneTwoThree} localImages={props.localImages}/> : null}
          {props.content.oneTwoThreeB.active === true ? <OneTwoThreeB content={props.content.oneTwoThreeB} localImages={props.localImages} bg={props.global.design.bg}/> : null}
         {props.content.showServices ? 
          <Container>
              <div style={{marginTop: 180, marginBottom: 50}}>
                <h2 style={{textAlign: 'center'}}>{servicesWord}</h2>
                <p style={{textAlign: 'center'}}>{''}</p>
              </div>
              <Row data-aos="fade-up">
                <Cards pages={services} localImages={props.localImages}/>
              </Row>
          </Container>
          : null}
          {props.content.showPortfolio ? 
          <Container>
              <div style={{marginTop: 180, marginBottom: 50}}>
                <h2 style={{textAlign: 'center'}}>{servicesWord}</h2>
                <p style={{textAlign: 'center'}}>{''}</p>
              </div>
              <Row data-aos="fade-up">
                <Cards pages={portfolio} localImages={props.localImages}/>
              </Row>
          </Container>
          : null}
          </>}

        </>
    )
}



export default Lead